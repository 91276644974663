import React, { Component } from "react";
import Page from "../components/Page";
import StoreLocator from "../components/StoreLocator";

class storeLocator extends Component {
  componentDidMount() {
    const config = {
      selector: ".store-locator-widget",
      account: "vxw8Va2JaE"
    };

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const yourFunction = async () => {
      await delay(1000);
      console.log("Waited 5s");
      window.StoreRocket.init(config);
    };

    yourFunction();

    // ;
  }

  render() {
    return (
      <Page>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: `
            <div id='storerocket-widget' style='width:100%;' data-storerocket-env='p' data-storerocket-id='vxw8Va2JaE'><p style='text-align:center;font-size:13px;padding:10px;'>Store locator is loading from StoreRocket <a target='_blank' href='https://storerocket.io' style='font-size:13px;'>Store Locator Widget</a>..</p></div><script>(function(){var a=document.createElement('script');a.type='text/javascript';a.async=!0;a.src='https://cdn.storerocket.io/js/widget-mb.js';var b=document.getElementsByTagName('script')[0];b.parentNode.insertBefore(a,b);}());</script>
        `
          }}
        /> */}
        <div className="store-locator-widget" />
      </Page>
    );
  }
}

export default storeLocator;
